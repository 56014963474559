define("discourse/plugins/discpage/discourse/lib/lib", ["exports", "discourse/lib/plugin-api", "discourse/lib/formatter", "discourse-common/lib/icon-library", "discourse/models/user", "discourse/components/topic-navigation", "discourse/routes/application", "ember", "@ember/runloop"], function (_exports, _pluginApi, _formatter, _iconLibrary, _user, _topicNavigation, _application, _ember, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.init = init;
  var w = function () {
      for (var _len = arguments.length, a = new Array(_len), _key = 0; _key < _len; _key++) {
        a[_key] = arguments[_key];
      }
      a = ["%cDiscPage -", "color:grey", ...a];
      console.log(...a);
    },
    y = function () {
      for (var _len2 = arguments.length, a = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        a[_key2] = arguments[_key2];
      }
      a = ["%cDiscPage Error -", "color:red", ...a];
      console.log(...a);
    },
    z = function () {
      for (var _len3 = arguments.length, a = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        a[_key3] = arguments[_key3];
      }
      a = ["%cDiscPage Warning -", "color:orange", ...a];
      console.log(...a);
    },
    C = class extends Error {
      constructor(a) {
        super(a);
        this.constructor = C;
        this.__proto__ = C.prototype;
        this.message = a;
        this.name = "DiscpageError";
      }
    },
    D = (a, b) => {
      if (!a) throw new C(`Assertion Failed${b ? " - " + b : ""}`);
    };
  function E(a, b, d, f) {
    let e = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
    let l = (c, g) => {
        try {
          const k = b(a[e], e, a);
          k && k.then ? k.then(c).catch(g) : c(k);
        } catch (k) {
          g(k);
        }
      },
      h = (c, g) => () => E(a, b, c, g, ++e),
      r = (c, g) => e < a.length ? new Promise(l).then(h(c, g)).catch(g) : c();
    return d ? r(d, f) : new Promise(r);
  }
  var F = a => new Promise(b => {
    setTimeout(() => {
      b(void 0);
    }, a);
  });
  function G(a, b, d) {
    let f = e => F(d).then(() => a(e));
    try {
      return 0 === b ? Promise.reject(void 0) : Promise.resolve(a(b)).then(e => e || G(f, b - 1));
    } catch (e) {
      return Promise.reject(e);
    }
  }
  var H = /^[0-9]+$/,
    J = /^[0-9A-Za-z_]+$/;
  function aa(_ref) {
    let {
      a,
      u: b
    } = _ref;
    if (!H.test(a)) throw new C(`Invalid pageId "${a}"`);
    if (b && !J.test(b)) throw new C(`Invalid balloon id "${b}". Valid characters are: [0-9A-Za-z_].`);
    return b ? `${"dpg"}-${a}-${b}` : `${"dpg"}-${a}`;
  }
  function K(a) {
    var b = a.split("-");
    if ("dpg" !== b.shift()) return null;
    a = b.shift();
    return H.test(a) ? (b = b.shift()) && !J.test(b) ? null : {
      a,
      u: b
    } : null;
  }
  function L(_ref2) {
    let {
      method: a,
      path: b,
      I: d
    } = _ref2;
    return new Promise((f, e) => {
      $.ajax({
        type: a,
        url: b,
        data: d,
        success: l => f(l)
      }).fail(l => e(l.responseText));
    });
  }
  function ba(_ref3) {
    let {
      tag: a
    } = _ref3;
    return L({
      method: "GET",
      path: `/tag/${a}.json`
    }).then(b => b.topic_list.topics);
  }
  function ca(_ref4) {
    let {
      name: a,
      F: b,
      P: d = !1,
      R: f = !1
    } = _ref4;
    L({
      method: "POST",
      path: "/tag_groups",
      I: {
        name: a,
        tag_names: b,
        one_per_topic: d,
        permissions: f ? {
          staff: 1
        } : void 0
      }
    });
  }
  function da(_ref5) {
    let {
      id: a,
      F: b
    } = _ref5;
    L({
      method: "PUT",
      path: `/tag_groups/${a}.json`,
      I: {
        tag_names: b
      }
    });
  }
  function N(a, _ref6) {
    let {
      f: b
    } = _ref6;
    var d = $(a.left);
    d.find(".dpg-balloon-text, .dpg-subsec").removeClass("dpg-highlighted");
    b && (d = d.find(`.dpg-balloon-text[data-dpg-id=${b}]`), d.length ? (d.addClass("dpg-highlighted"), d.parent().is("h1,h2,h3,h4,h5,h6") && d.closest(".dpg-subsec").addClass("dpg-highlighted"), b = d[0].getBoundingClientRect(), a = a.left.getBoundingClientRect(), b.top < a.bottom && b.bottom >= a.top || d[0].scrollIntoView()) : z(`selected balloon "${b}" has not been found in page "${a.a}"`));
  }
  function O(a, _ref7) {
    let {
      a: b,
      j: d,
      i: f,
      g: e,
      o: l,
      c: h,
      title: r,
      f: c,
      h: g
    } = _ref7;
    D("string" == typeof b, `invalid pageId "${b}"`);
    h = h.replace("{dpg-show-rev-button}", "").replace("{dpg-title-balloon}", "");
    let k = $(`
      <div class="dpg-page-content">
        <div class="dpg-buttons ${"nodiff" !== e ? "selected" : ""}">
          <div class="dpg-buttons-left"></div><div class="dpg-buttons-center"></div><div class="dpg-buttons-right"></div>        
        </div>
        <div class="dpg-header">
          <div class="dpg-header-1"><div class="dpg-header-2"><div class="dpg-header-3"></div></div></div>
        </div>
        <div class="dpg-body">
          <div class="wrap">
            <!-- <div class="posts-wrapper"> FIX FOR ISSUE https://github.com/sylque/discpage/issues/6 --> 
              <div class="topic-body">
                <!-- Cooked post to be inserted here -->
              </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="dpg-footer">
          <div class="dpg-footer-1"><div class="dpg-footer-2"><div class="dpg-footer-3"></div></div></div>
        </div>
      </div>
    `);
    var m = a.c.includes("{dpg-title-balloon}") ? '<span class="dpg-balloon-text" data-dpg-id="title"></span>' : "";
    m = g.cooked(`<h1>${r + m}</h1>\n` + h).init();
    k.find(".dpg-body .topic-body").append(m);
    let u = a.l.siteSettings.force_lowercase_tags,
      x = a.l.siteSettings.max_tag_length;
    k.find(".dpg-badge").hide();
    let A = {};
    k.find(".dpg-balloon-text").each((n, q) => {
      let p,
        t = q.dataset.dpgId;
      n = $(q);
      try {
        if (!t) throw new C("Missing balloon id. The correct syntax is [dpgb id=something][/dpgb].");
        p = aa({
          a: b,
          u: t
        });
        if (p.length > x) throw new C(`Balloon id is too long. Resulting tag is \"${p}\", which has a length of ${p.length}. This doesn't fit max_tag_length=${x} in Discourse settings. Fix: either shorten the balloon id, or increase max_tag_length.`);
        if (u && p !== p.toLowerCase()) throw new C("Balloon id has uppercase. This doesn't fit force_lowercase_tags=true in Discourse settings. Fix: either make your balloon id all lowercase, or set force_lowercase_tags to false.");
        A[p] && z(`Duplicate balloon id "${p}". This is usually a bad idea.`);
      } catch (v) {
        if (v instanceof C) {
          y(v.message);
          n.append(`<span class="dpg-error" title="${v.message}">DiscPage Error</span>`);
          return;
        }
        throw v;
      }
      A[p] = !0;
      if (0 === q.childNodes.length) {
        q = n.parent().is(".cooked,.dpg-subsec");
        let v = n.prev();
        q = q && v.length ? v : n.parent();
        n.detach();
        q.addClass("dpg-balloon-parent").wrapInner(n);
      } else n.wrap('<span class="dpg-balloon-parent" />'), q = n.parent();
      q.append(`
        <span class="dpg-icons" title="Click to discuss this part">
          <span class="dpg-balloon">${(0, _iconLibrary.iconHTML)("comment")}</span>
          <span class="dpg-badge" style="display:none">99</span>
        </span>
      `);
      q.is("h1,h2,h3,h4,h5,h6") && q.nextUntil("h1,h2,h3,h4,h5,h6").addBack().wrapAll('<div class="dpg-subsec"></div>');
      q.find(".dpg-icons").click(v => {
        a.l.get("router").transitionTo(`/tag/${p}`);
        N(a, {
          f: t
        });
        v.stopPropagation();
      });
    });
    let B = Object.keys(A);
    a.G && B.length && a.M.then(n => {
      let q = `dpg-${b}`;
      n = n.find(p => p.name === q);
      B.sort();
      n ? ea(n.J, B) || da({
        id: n.id,
        F: B
      }) : ca({
        name: q,
        F: B
      });
    });
    a.N.then(n => {
      n = n.reduce((q, p) => {
        if (p.count && p.parsed.a === b) {
          const t = k.find(`.dpg-balloon-text[data-dpg-id="${p.parsed.u}"]`);
          t.length ? (p.K = t.next().find(".dpg-badge"), q.push(p)) : z(`In page "${b}": missing balloon for tag "${p.id}"`);
        }
        return q;
      }, []);
      E(n, q => ba({
        tag: q.id
      }).then(p => {
        (p = p.filter(t => t.visible).length) && q.K.text(p).show();
      }).then(() => F(250)));
    });
    m = k.find(".dpg-buttons-right");
    let M = k.find(".dpg-buttons-center");
    var I = a.c.includes("{dpg-show-rev-button}");
    if (!a.D && 1 < f && I) {
      let n = _ref8 => {
          let {
            g: p,
            rev: t = null
          } = _ref8;
          O(a, {
            a: b,
            j: d,
            i: f,
            g: p,
            o: t ? t.created_at : void 0,
            c: t ? t.body_changes.inline : a.c,
            title: r,
            f: c,
            h: g
          });
        },
        q = "nodiff" !== e;
      P({
        s: "history",
        title: "Show page revisions",
        id: "dpg-show-rev-nav"
      }).click(() => {
        q ? n({
          g: "nodiff"
        }) : Q(`/posts/${d}/revisions/${f}.json`).then(p => {
          n({
            g: f,
            rev: p
          });
        });
      }).appendTo(m);
      if (q) {
        P({
          s: "backward",
          title: "Previous revisions",
          id: "dpg-prev-rev",
          disabled: 2 === e
        }).appendTo(M).click(() => {
          let t = e - 1;
          Q(`/posts/${d}/revisions/${t}.json`).then(v => {
            n({
              g: t,
              rev: v
            });
          });
        });
        I = new Date(l);
        let p = (0, _formatter.relativeAge)(I, {
          format: "medium-with-ago"
        });
        M.append(`<span class="dpg-date" title=${I}>${p}</span>`);
        P({
          s: "forward",
          title: "Next revision",
          id: "dpg-next-rev",
          disabled: e === f
        }).appendTo(M).click(() => {
          let t = e + 1;
          Q(`/posts/${d}/revisions/${t}.json`).then(v => {
            n({
              g: t,
              rev: v
            });
          });
        });
      }
    }
    a.G && (P({
      s: "wrench",
      title: "Edit title",
      id: "dpg-edit-title-button"
    }).click(() => {
      $("html").toggleClass("dpg", !1);
      $("a.edit-topic").click();
      $("#main-outlet").click(n => {
        n.target.closest(".edit-controls .btn, .topic-admin-popup-menu .topic-admin-reset-bump-date, .topic-admin-popup-menu .topic-admin-visible") && (O(a, {
          a: b,
          j: d,
          i: f,
          g: e,
          o: l,
          c: h,
          title: $("input#edit-title").val(),
          f: c,
          h: g
        }), $("html").toggleClass("dpg", !0));
      });
    }).wrap("<div><div>").parent().appendTo(m), P({
      s: "pencil-alt",
      title: "Edit page",
      id: "dpg-edit-page-button"
    }).click(() => {
      let n = $("article#post_1 button.edit");
      n.length ? (n.click(), R(a.D)) : ($("article#post_1 button.show-more-actions").click(), setTimeout(() => {
        $("article#post_1 button.edit").click();
        R(a.D);
      }, 0));
    }).wrap("<div><div>").parent().appendTo(m));
    $(a.left).empty().append(k);
    N(a, {
      f: c
    });
    document.documentElement.dispatchEvent(new CustomEvent("dpg_displaypage", {
      detail: {
        pageId: parseInt(b),
        title: r,
        cooked: h,
        node: k[0],
        selTriggerId: c,
        curRevNum: e,
        curRevDate: l
      }
    }));
  }
  function S(a, b) {
    O(a, {
      a: "error",
      j: void 0,
      i: void 0,
      g: "nodiff",
      o: void 0,
      c: "<p>Please contact your administrator.</p>",
      title: "Oops! That page doesn't exist anymore",
      f: null,
      h: b
    });
  }
  function T(a, _ref9) {
    let {
      a: b,
      j: d,
      i: f,
      c: e,
      title: l,
      f: h,
      h: r
    } = _ref9;
    D("string" === typeof b);
    b === a.a && 1 !== a.m || h || a.left.scrollTo(0, 0);
    d && f && e && l ? b === a.a && e === a.c ? N(a, {
      f: h
    }) : (a.a = b, a.c = e, O(a, {
      a: b,
      j: d,
      i: f,
      g: "nodiff",
      o: void 0,
      c: e,
      title: l,
      f: h,
      h: r
    })) : b === a.a ? N(a, {
      f: h
    }) : Q(`/t/${b}.json`).then(c => {
      a.a = b;
      if (a.L.find(g => g.id === c.category_id)) {
        let g = c.post_stream.posts[0];
        a.c = g.cooked;
        O(a, {
          a: b,
          j: g.id,
          i: g.version,
          g: "nodiff",
          o: void 0,
          c: a.c,
          title: c.fancy_title,
          f: h,
          h: r
        });
      } else a.c = "error", w(`Won't display static page ${b}, because category ${c.category_id} is not a static page`), S(a, r);
    }).catch(() => {
      a.c = "error";
      w(`Won't display static page ${b}, because it doesn't exist or is private`);
      S(a, r);
    });
  }
  function U(a, b, d, f) {
    a.H.animate ? (a = a.H.animate([{
      left: b
    }, {
      left: d
    }], {
      duration: 200
    }), f && (a.onfinish = f)) : f && f();
  }
  function fa(a, b) {
    U(a, "100%", 1035 <= window.innerWidth ? "50%" : "0%", b);
  }
  function V(a, b) {
    if (b !== a.m) {
      switch (a.m) {
        case null:
        case 1:
          $("html").attr("data-dpg-layout", b);
          break;
        case 0:
        case 2:
          3 === b ? fa(a, () => {
            $("html").attr("data-dpg-layout", b);
          }) : $("html").attr("data-dpg-layout", b);
          break;
        case 3:
          $("html").attr("data-dpg-layout", b);
          0 !== b && 2 !== b || U(a, 1035 <= window.innerWidth ? "50%" : "0%", "100%");
          break;
        default:
          throw new C(void 0);
      }
      if (2 == b) {
        let d = $(a.left).find(".dpg-balloon-text.dpg-highlighted");
        d.length && d[0].scrollIntoView();
      }
      a.m = b;
    }
  }
  class ha {
    constructor(a, b) {
      this.l = a;
      this.L = b;
      this.D = a.site.mobileView;
      this.left = document.getElementById("dpg-left");
      this.H = document.getElementById("dpg-ghost");
      this.c = this.a = this.m = null;
      this.G = (a = _user.default.current()) && a.admin;
      this.N = L({
        method: "GET",
        path: "/tags.json"
      }).then(d => d.tags.reduce((f, e) => {
        e.parsed = K(e.id);
        return e.parsed ? [...f, e] : f;
      }, []));
      this.G && (this.M = L({
        method: "GET",
        path: "/tag_groups.json"
      }).then(d => d.tag_groups.reduce((f, e) => {
        e = {
          id: e.id,
          name: e.name,
          J: e.tag_names
        };
        if (e.name && e.name.startsWith("dpg-")) {
          if (J.test(e.name.substring(4))) return e.J.sort(), [...f, e];
          z(`Invalid discpage tag group "${e.name}"`);
        }
        return f;
      }, [])));
    }
  }
  function W() {
    $("html").toggleClass("dpg-wide", 1035 <= window.innerWidth);
  }
  window.addEventListener("resize", W);
  W();
  let Q = a => new Promise((b, d) => {
    $.get(a, f => b(f)).fail(() => d(`get "${a}" failed`));
  });
  function ea(a, b) {
    return a.length === b.length && a.every((d, f) => d === b[f]);
  }
  function P(_ref10) {
    let {
      s: a,
      title: b,
      id: d = "",
      O: f = "",
      disabled: e = !1
    } = _ref10;
    return $(`    
    <button ${b ? `title="${b}"` : ""} ${d ? `id="${d}"` : ""} ${e ? 'disabled=""' : ""} class="btn-default btn no-text btn-icon ${f || ""}" type="button">    
      <svg class="fa d-icon d-icon-${a} svg-icon svg-string" xmlns="http://www.w3.org/2000/svg">
        <use xlink:href="#${a}"></use>
      </svg>
    </button>
  `);
  }
  function R(a) {
    a || setTimeout(() => {
      $("button.toggle-fullscreen").click();
      setTimeout(() => {
        $(".save-or-cancel").append('<span style="color:#646464">ctrl+enter = submit | esc = exit</span>');
      }, 500);
    }, 500);
  }
  function ia(a, b, d) {
    function f(c) {
      let g = a.b.left;
      g.scrollTop >= g.scrollHeight - g.clientHeight - 1 && c.preventDefault();
    }
    let e = a.lookup("controller:application"),
      l = "dpg",
      h = document.createElement("style");
    document.head.appendChild(h);
    b.forEach(c => {
      c = c.topic_url.split("/").pop();
      h.sheet.insertRule(`html.dpg .category-page .topic-list-item.category-page[data-topic-id="${c}"] { display: none; }`);
    });
    d && (l += " dpg-hide-balloon-cat", d.forEach(c => {
      let g = c.slug;
      h.sheet.insertRule(`html.dpg.dpg-hide-balloon-cat .category-chooser .category-row[data-name="${c.name}"] { display: none; }`);
      (c = c.parentCategory) ? (c = c.slug, h.sheet.insertRule(`html.dpg body.category-${c}-${g} .category-navigation button#create-topic { opacity: 0.5; pointer-events: none; }`), h.sheet.insertRule(`html.dpg body.category-${c}-${g} .topic-list-bottom .footer-message { display: none; }`)) : (h.sheet.insertRule(`html.dpg body.category-${g} .category-navigation button#create-topic { opacity: 0.5; pointer-events: none; }`), h.sheet.insertRule(`html.dpg body.category-${g} .topic-list-bottom .footer-message { display: none; }`));
    }));
    e.siteSettings.discpage_hide_sugg_topics && (l += " dpg-disable-sugg");
    e.siteSettings.discpage_hide_tags && (l += " dpg-hide-tags");
    $("html").addClass(l);
    $("body").prepend('\n    <div id="dpg-ghost">\n      <div class="dpg-ghost-splitbar"></div>\n    </div>\n    <div id="dpg-container">\n      \x3c!-- <div id="dpg-ios-wrapper" tabindex="0"> --\x3e\n        <div id="dpg-left" tabindex="0">\n          \x3c!--\n          <div class="container">\n            <div class="loading-container visible ember-view">    \n              <div class="spinner "></div>\n            </div>      \n          </div>                \n          --\x3e\n        </div>\n        \x3c!-- </div> --\x3e\n      <div id="dpg-splitbar">\n        <div style="flex:1 0 0"></div>\n        <div id="dpg-splitbar-text">&gt;</div>\n        <div style="flex:1 0 0"></div>\n      </div>\n    </div>\n  ');
    a.b = new ha(e, b);
    a.b.left.addEventListener("wheel", c => {
      0 > c.deltaY ? 0 === a.b.left.scrollTop && c.preventDefault() : 0 < c.deltaY && f(c);
    }, {
      passive: !1
    });
    a.b.left.addEventListener("keydown", c => {
      c.shiftKey || c.altKey || c.ctrlKey || c.metaKey || ("ArrowUp" !== c.code && "PageUp" !== c.code || 0 !== a.b.left.scrollTop || c.preventDefault(), "ArrowDown" !== c.code && "PageDown" !== c.code || f(c));
    });
    let r = a.lookup("router:main");
    $("#dpg-splitbar").click(function () {
      let c = !a.b.l.get("showRight");
      r.transitionTo({
        queryParams: {
          showRight: c
        }
      });
    });
    a.b.left.addEventListener("click", c => {
      2 !== a.b.m && 3 !== a.b.m || c.shiftKey || c.ctrlKey || window.getSelection().toString() || c.target.closest(".lightbox-wrapper") || c.target.closest(".dpg-buttons") || r.transitionTo(`/t/${a.b.a}`);
    });
    document.addEventListener("click", c => {
      c.target.closest('[data-link-name="dpg-on-off"]') && $("html").toggleClass("dpg");
    });
    $(document).keydown(function (c) {
      65 === c.keyCode && c.altKey && ((c = _user.default.current()) && c.admin ? $("html").toggleClass("dpg") : w("Only admins can do that"));
    });
  }
  function ja(_ref11) {
    let {
      v: a,
      B: b,
      A: d,
      w: f,
      C: e
    } = _ref11;
    if (b.startsWith("topic.")) {
      let l = a.lookup("route:topic").modelFor("topic");
      if (!f.includes(l.get("category_id"))) {
        G(() => l.hasOwnProperty("tags"), 15, 200).then(() => {
          X({
            v: a,
            B: b,
            A: d,
            w: f,
            C: e
          });
        }, () => {
          V(a.b, 1);
        });
        return;
      }
    }
    X({
      v: a,
      B: b,
      A: d,
      w: f,
      C: e
    });
  }
  function X(_ref12) {
    let {
      v: a,
      B: b,
      A: d,
      w: f,
      C: e
    } = _ref12;
    let l = $("html");
    l.removeClass("dpg-page dpg-tag dpg-topic dpg-comment dpg-discuss");
    l.removeAttr("data-dpg-page-id");
    if (b.startsWith("topic.")) {
      let h = a.lookup("route:topic").currentModel;
      if (f.includes(h.get("category_id"))) {
        l.addClass("dpg-page");
        l.attr("data-dpg-page-id", h.get("id"));
        return;
      }
      let r,
        c = (h.get("tags") || []).find(g => {
          r = K(g);
          return !!r;
        });
      if (c) {
        let {
          a: g,
          u: k
        } = r;
        b = a.b.l.get("showRight") ? 3 : 2;
        T(a.b, {
          a: g,
          f: k
        });
        l.addClass("dpg-topic dpg-discuss");
        l.attr("data-dpg-page-id", g);
        d || Y().then(() => {
          $("#dpg-back").length || $("#topic-title .title-wrapper").append(`
        <div id="dpg-back">
          <a href="/tag/${c}">
            &#8630; Back to topic list
          </a>
        </div>
      `);
        });
        V(a.b, b);
        return;
      }
    }
    if ("tag.show" === b && (b = a.lookup("route:tag.show").currentModel, b = K(b.tag.id))) {
      l.addClass("dpg-tag dpg-discuss");
      l.attr("data-dpg-page-id", b.a);
      d || (T(a.b, {
        a: b.a,
        f: b.u
      }), e && (1 === e.length ? a.currentTriggerCatId = e[0].id : ka(`/t/${b.a}.json`).then(h => {
        let r = h.category_id,
          c = a.lookup("controller:application").site.categories.find(g => g.id === r).parent_category_id;
        h = c && e.find(g => g.parent_category_id === c || g.id === c) || e[0];
        a.currentTriggerCatId = h.id;
      })), Y().then(() => {
        {
          let h = $("footer.topic-list-bottom");
          $("table.topic-list").length ? h.html("") : h.html('\n      <div style="margin-left:12px">\n        <p><i>No topic yet</i></p>\n      </div>\n    ');
        }
      }));
      d = a.b.l.get("showRight") ? 3 : 2;
      V(a.b, d);
      return;
    }
    V(a.b, 1);
  }
  let Y = () => new Promise(a => {
      (0, _runloop.schedule)("afterRender", null, () => a(void 0));
    }),
    ka = a => new Promise((b, d) => {
      $.get(a, f => b(f)).fail(() => d(`get "${a}" failed`));
    }),
    la = () => new Promise(a => {
      (0, _runloop.schedule)("afterRender", null, () => a(void 0));
    });
  function Z(a, b) {
    y(`Invalid Discourse setting "${a.replace(/_/g, " ")}": ${b}`);
  }
  function init(a) {
    var b = a.lookup("site-settings:main");
    let d = _user.default.current(),
      f = d && d.admin;
    if (b.discpage_enabled && (!b.login_required || d)) if (b.tagging_enabled) {
      if ("header dropdown" != b.navigation_menu) Z("navigation_menu", 'this must be set to "Header Dropdown"');else if (b.discpage_page_categories) {
        var e = b.discpage_page_categories.split("|").map(k => parseInt(k)),
          l = a.lookup("controller:application"),
          h = !1,
          r = e.reduce((k, m) => {
            const u = l.site.categories.find(x => x.id === m);
            u ? k.push(u) : f && (Z("discpage_page_categories", `category "${m}" not found. Please reset this setting and add your category(ies) again`), h = !0);
            return k;
          }, []);
        if (!h) {
          b = l.siteSettings.discpage_balloon_category;
          h = !1;
          var c = b && b.split("|").reduce((k, m) => {
            const u = parseInt(m);
            (m = l.site.categories.find(x => x.id === u)) ? k.push(m) : f && (Z("discpage_balloon_category", `category "${u}" not found. Please reset this setting and add your category(ies) again`), h = !0);
            return k;
          }, []);
          if (!h) {
            la().then(() => {
              ia(a, r, c);
            });
            a.lookup("controller:application").reopen({
              queryParams: {
                showRight: "r"
              },
              showRight: !0
            });
            var g = "";
            (0, _pluginApi.withPluginApi)("0.8.36", k => {
              k.modifyClass("component:discourse-topic", {
                pluginId: "discPage",
                shouldShowTopicInHeader() {
                  return !1;
                }
              });
              f && k.addCommunitySectionLink({
                name: "dpg-on-off",
                title: "DiscPage On/Off",
                text: "DiscPage On/Off",
                icon: "power-off"
              });
              k.modifyClass("model:composer", {
                pluginId: "discPage",
                open(m) {
                  m && "createTopic" === m.action && m.topicTags && K(m.topicTags) && (m.categoryId = a.currentTriggerCatId);
                  return this._super(...arguments);
                }
              });
              k.decorateWidget("post:after", m => {
                let u = m.attrs;
                if (u.firstPost) {
                  var x = a.lookup("controller:topic").get("model.category_id");
                  r.find(A => A.id == x) && (m.widget.postContentsDestroyCallbacks = [], la().then(() => {
                    T(a.b, {
                      a: u.topicId.toString(),
                      j: u.id,
                      i: u.version,
                      c: u.cooked,
                      title: $(".fancy-title").text().trim(),
                      h: m
                    });
                    V(a.b, 0);
                  }));
                }
              });
              k.onAppEvent("page:changed", _ref13 => {
                let {
                  ["currentRouteName"]: m,
                  ["url"]: u
                } = _ref13;
                if (u !== g) {
                  var x = u.split("?")[0] === g.split("?")[0];
                  g = u;
                  ja({
                    v: a,
                    B: m,
                    A: x,
                    w: e,
                    C: c
                  });
                }
              });
            });
            _topicNavigation.default.reopen({
              _performCheckSize() {
                this._super();
                1005 >= $("html.dpg.dpg-topic #main-outlet").width() && this.info.setProperties({
                  renderTimeline: !1
                });
              },
              didInsertElement() {
                this._super(...arguments);
                this.observer = new MutationObserver(k => {
                  k.forEach(m => {
                    "class" === m.attributeName && m.target.classList.contains("dpg-topic") && this._performCheckSize();
                  });
                });
                this.observer.observe(document.documentElement, {
                  attributes: !0
                });
              },
              willDestroyElement() {
                this.observer.disconnect();
                this._super(...arguments);
              }
            });
            _application.default.reopen({
              S: _ember.default.observer("topicTrackingState.messageCount", function () {})
            });
          }
        }
      } else Z("discpage_page_categories", "missing setting");
    } else Z("tagging_enabled", "this must be set to true");
  }
});